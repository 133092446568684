<template>
   <div class="activDetails">
     <div class="hd_head">
       <div class="hd_head_fanhui">
         <van-icon name="arrow-left" @click="$router.go(-1)" color="#ffffff" size="24" />
       </div>
       <van-image :src="active.cover" class="hd_banner" >
            <template v-slot:error>加载失败</template>
       </van-image>
     </div>
     <div class="ad_cont">
       <div class="ad_cont_title">{{active.title}}</div>
       <div style="text-align:justify;text-justify:inter-ideograph;" v-html="active.content"></div>
     </div>
     <!-- 评论 -->
      <SHComment  @praise="praiseOrCollect" @collect="praiseOrCollect"  :isPraise="active.isPraise" :isCollect="active.isCollect" :bizId="contentId" :bizType="bizType" :star="active.star" :collectNum="active.collect" :praiseNum="active.praise"></SHComment>



   </div>
</template>

<script src="./activDetails.js">

</script>

<style scoped src="./activDetails.css">

</style>
